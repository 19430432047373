import { useCallback } from 'react'
import {
  Loading,
  TextField,
  NumberField,
  TextInput,
  DateField,
  NullableBooleanInput,
} from 'react-admin'
import { DateInput, Datagrid, List, Filter } from '../../custom'
import {
  currencyFieldOptions,
  numberFieldOptions,
  dateFieldOptions,
} from '../../util/component-options'
import { useEarliestIncompletePeriod, useLabel } from '../../../hooks'
import PeriodDatePicker from '../../shared/period-date-picker'

const HourlyTicketsFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <PeriodDatePicker label={label('hourly_ticket_invoice_period_end_date')} source='hourly_ticket_invoice_period_end_date_eq' />
      <NullableBooleanInput label={label('snapshot_mode')} source='snapshot_mode_true' />
      <TextInput label={label('ticket_number', 'find')} source='ticket_number_start' />
      <DateInput label={label('worked_at_date', 'start')} source='worked_at_date_gteq' />
      <DateInput label={label('worked_at_date', 'end')} source='worked_at_date_lteq' />
      <TextInput label={label('customer', 'filter')} source='customer_name_i_cont' />
      <TextInput label={label('driver', 'filter')} source='driver_name_i_cont' />
      <TextInput label={label('truck', 'filter')} source='truck_number_i_cont' />
    </Filter>
  )
}

const HourlyTicketsList = props => {
  const label = useLabel(props)

  const hasEdit = useCallback(record => {
    return !record.snapshot_mode
  }, [])

  const hasShow = useCallback(record => {
    return !hasEdit(record)
  }, [hasEdit])

  const period = useEarliestIncompletePeriod()

  if (!period) return <Loading />

  return (
    <List
      {...props}
      filters={<HourlyTicketsFilter />}
      filterDefaultValues={{ hourly_ticket_invoice_period_end_date_eq: period?.end_date }}
    >
      <Datagrid
        hasEdit={hasEdit}
        hasShow={hasShow}
      >
        <TextField source='ticket_number' label={label('ticket_number')} />
        <TextField source='customer.name' sortBy='customer_name' label={label('customer')} />
        <TextField source='driver.name' sortBy='driver_name' label={label('driver')} />
        <TextField source='truck.number' sortBy='truck_number' label={label('truck')} />
        <NumberField source='regular_time_in_hours' label={label('regular_time_in_hours')} options={numberFieldOptions} />
        <NumberField source='overtime_in_hours' label={label('overtime_in_hours')} options={numberFieldOptions} />
        <NumberField source='doubletime_in_hours' label={label('doubletime_in_hours')} options={numberFieldOptions} />
        <NumberField source='regular_rate_in_dollars' label={label('regular_rate_in_dollars')} options={currencyFieldOptions} />
        <NumberField source='overtime_rate_in_dollars' label={label('overtime_rate_in_dollars')} options={currencyFieldOptions} />
        <NumberField source='doubletime_rate_in_dollars' label={label('doubletime_rate_in_dollars')} options={currencyFieldOptions} />
        <DateField source='worked_at_date' label={label('worked_at_date')} options={dateFieldOptions}/>
      </Datagrid>
    </List>
  )
}

export default HourlyTicketsList
