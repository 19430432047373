import {
  useTranslate,
} from 'react-admin'
import {
  Grid,
  Typography,
  Box,
  makeStyles,
} from '@material-ui/core'
import {
  dateFieldOptions,
} from '../util/component-options'

const useStyles = makeStyles(({ shape, spacing }) => {
  return {
    spaced: {
      marginBottom: spacing(4)
    },
    totals : {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'inherit',
      borderRadius: shape.borderRadius,
      '@media print': {
        pageBreakInside: 'avoid',
      },
    },
    break: {
      '@media print': {
        pageBreakBefore: 'always',
      },
    },
    ticketImages: {
      '@media print': {
        flexWrap: 'wrap',
      },
    },
    imageCol: {
      '@media print': {
        pageBreakAfter: 'always',
        pageBreakInside: 'avoid',
      },
    },
    ticketImage: {
      '@media print': {
        padding: spacing(1),
        objectFit: 'contain',
        width: '100%',
        maxHeight: '100vh',
      },
    },
  }
})

const CustomerField = ({ record }) => {
  return (
    <>
      <Typography>
        {record.name}
      </Typography>
      <Typography>
        {record.billing_address_street_1}
      </Typography>
      { record.billing_address_street_2 &&
        <Typography>
          {record.billing_address_street_2}
        </Typography>
      }
      {
        record.billing_address_city && record.billing_address_united_state_abbreviation && record.billing_address_zip &&
        <Typography>
          {`${record.billing_address_city}, ${record.billing_address_united_state_abbreviation} ${record.billing_address_zip}`}
        </Typography>
      }
    </>
  )
}

const TenantField = ({ record }) => {
  return (
    <>
      <Typography variant='h6'>
        {record.name}
      </Typography>
      <Typography>
        {record.address_street_1}
      </Typography>
      <Typography>
        {`${record.address_city}, ${record.address_united_state_abbreviation} ${record.address_zip}`}
      </Typography>
    </>
  )
}

const Invoice = ({
  customer,
  invoiceNumber,
  date,
  detail,
  lineItems,
  totals,
  tenant,
  ticketImages,
  isPrintView,
}) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <Box>
      <Grid container className={classes.spaced}>
        <Grid item xs={6}>
          <TenantField record={tenant} />
        </Grid>
        <Grid item xs={6}>
          <Typography align='right' variant='h6'>
            {`${translate('invoice.invoice')}: ${invoiceNumber}`}
          </Typography>
          <Typography align='right'>
            {new Date(date).toLocaleString([], dateFieldOptions)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.spaced}>
        <Grid item xs={6}>
          <Typography>
            {`${translate('invoice.sold_to')}:`}
          </Typography>
          <CustomerField record={customer} />
        </Grid>
        <Grid item xs={6}>
          {detail}
        </Grid>
      </Grid>

      <Grid container className={classes.spaced}>
        {lineItems}
      </Grid>

      <Grid container className={classes.totals}>
        {totals}
      </Grid>

      {
        isPrintView ? (
          Boolean(ticketImages?.length) &&
          <>
            <div className={classes.break} />
            <Grid container className={classes.ticketImages}>
              {
                ticketImages.map((image, index) => (
                  <Grid item xs={12} className={classes.imageCol} key={index}>
                    <Box component='img' className={classes.ticketImage} src={image} alt='ticket' />
                  </Grid>
                ))
              }
            </Grid>
          </>
        ) : (
          // AWS urls expire - ensure images are preloaded for print view
          Boolean(ticketImages?.length) &&
          <>
            {
              ticketImages.map((image, index) => (
                <link rel='prefetch' as='image' href={image} key={index} />
              ))
            }
          </>
        )
      }
    </Box>
  )
}

export default Invoice
