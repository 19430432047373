import {
  Children,
  cloneElement
} from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import { DatagridHeaderCell, DatagridCell } from 'react-admin'
import classnames from 'classnames'
import set from 'lodash/set'
import get from 'lodash/get'

const useStyles = makeStyles(({ spacing }) => {
  return {
    table: {
      tableLayout: 'fixed',
    },
    headerCell: {
      fontWeight: 'bold',
      borderBottomWidth: 0,
      padding: `${spacing(1)}px ${spacing(3)}px`,

    },
    rowCell: {
      padding: `${spacing(1)}px ${spacing(3)}px`,
      borderWidth: 1,
      borderBottomWidth: 0,
      borderTopStyle: 'solid',
      borderTopColor: 'lightgray',
    },
    rowCellTotalContent: {
      fontWeight: 'bold',
    },
  }
})

const datagridCellDefaults = {
  currentSort: {},
  isSorting: false,
  updateSort: () => null,
}

const cellPlaceholderValue = '--'

const DataTable = ({
  children,
  data,
  className,
}) => {
  const classes = useStyles()

  const dataElements = Children.map(children, c => {
    const nextProps = Object.assign({},
    c.props, {
      className: classnames(c.props.className, classes.rowCellTotalContent),
    }, c.props.forceZero ? { forceZero: false } : null)
    return cloneElement(c, nextProps)
  })

  const headersElements = Children.map(children, c => cloneElement(c, {
    ...c.props,
    sortable: false,
    className: classnames(c.props.className, classes.headerCell)
  }))

  const getCellRecord = (field) => {
    const record = {...data}
    const { props: { source } } = field
    if (get(record, source) == null) {
      set(record, field.props.source, cellPlaceholderValue)
    }
    return record
  }

  return (
    <div className={className}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {
              headersElements.map((field, index) => (
                <DatagridHeaderCell
                  {...datagridCellDefaults}
                  className={classes.headerCell}
                  field={field}
                  key={index}
                />
              ))
            }
          </TableRow>
        </TableHead>
          {
            <TableBody>
              <TableRow>
                {
                  dataElements.map((field, index) => {
                    return (
                      <DatagridCell
                        className={classes.rowCell}
                        key={index}
                        field={field}
                        record={getCellRecord(field)}
                      />
                    )
                  })
                }
              </TableRow>
            </TableBody>
          }
      </Table>
    </div>
  )
}

export default DataTable
