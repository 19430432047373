import { useState, useMemo } from 'react'
import { useFindFromStore } from '.'

const useReferenceLookup = (resource, initialRate) => {
  const [selectedId, setSelectedId] = useState(initialRate?.id)
  const findRecordFromStore = useFindFromStore(resource)

  const selectedRecord = useMemo(() => {
    return findRecordFromStore(selectedId)
  }, [selectedId, findRecordFromStore])

  return {
    selectedRecord,
    onSelectedIdChange: setSelectedId,
    findRecordFromStore,
  }
}

export default useReferenceLookup
