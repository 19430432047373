import React from 'react'
import {
  TextField,
  TextInput,
} from 'react-admin'
import { Datagrid, List, Filter } from '../../custom'
import { useLabel, useAddressFields } from '../../../hooks'

const SitesFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <TextInput label={label('name', 'filter')} source='name_i_cont' />
      <TextInput label={label('company', 'filter')} source='company_name_i_cont' />
    </Filter>
  )
}

const SitesList = props => {
  const label = useLabel(props)
  const addressFields = useAddressFields()

  return (
    <List
      {...props}
      filters={<SitesFilter />}
    >
      <Datagrid>
        <TextField source='name' label={label('name')} />
        <TextField source='company.name' sortBy='company_name' label={label('company')} />
        {
          addressFields.map(({ source, label: _label }) => {
            return (
              <TextField key={source} source={source} sortBy={source} label={label(_label)} />
            )
          })
        }
      </Datagrid>
    </List>
  )
}

export default SitesList
