import React, { useCallback, useEffect } from 'react'
import {
  FormWithRedirect,
  SaveButton,
  DeleteButton,
  useRedirect,
  useMutation,
  useEditContext,
  Loading as RaLoading,
  useRefresh,
} from 'react-admin'
import { Link } from 'react-router-dom'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Tooltip, IconButton, Grid } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import CloneIcon from '@material-ui/icons/AddCircleOutline'
import ClearIcon from '@material-ui/icons/Clear'
import { useNotifyResponse, useEnterToTab } from './functions/'
import { formOptions } from '../util/component-options'
import { useFormStyles } from '../../styles'
import { useNonce, useSoundEffect, useDeleteConfirmText } from '../../hooks'
import errorSound from '../../audio/error.mp3'
import { relationships } from '../../data-model'
import { CUSTOM_MUTATION } from '../../actions'
import ButtonWithIcon from '../custom/button-with-icon'

const InfoTooltip = withStyles(theme => {
  return {
    tooltip: {
      ...theme.typography.caption
    }
  }
})(Tooltip)

const ToolbarGrid = withStyles(theme => {
  return {
    root: {
      marginTop: theme.spacing(4)
    }
  }
})(Grid)

const Loading = withStyles(() => {
  return {
    container: {
      marginTop: 0,
      height: '28vh',
    },
  }
})(RaLoading)

const useStyles = makeStyles(theme => ({
  cloneButton: {
    color: theme.palette.text.primary,
  },
}))

const formHOC = (Component) => {
  return props => {
    const {
      basePath,
      resource,
      record,
    } = props

    const {
      transform,
      onUpdateSuccess,
      useOptimisticSave = false,
      ...restProps
    } = props

    const recordId = record.id
    const isNewRecord = !recordId
    const isClonedRecord = Object.keys(record).length > 0
    const isOptimisticSave = useOptimisticSave && isNewRecord && !isClonedRecord
    const redirect = useRedirect()
    const [mutate] = useMutation()
    const playErrorSound = useSoundEffect(errorSound)

    // Use as nonce key for component to force a reset
    // without refreshing data store (i.e. via 'useRefresh' RA hook)
    const [formKey, resetFormKey] = useNonce()

    const {
      notifySuccess,
      notifyDeleteSuccess,
      notifyFailure,
      alertOptimisticFailure,
    } = useNotifyResponse(restProps)

    const onSuccess = useCallback((responseId) => {
      if (!isNewRecord) {
        onUpdateSuccess ? onUpdateSuccess() : redirect('list', basePath)
      } else if (isClonedRecord) {
        redirect('edit', basePath, responseId)
      } else if (!isOptimisticSave) {
        resetFormKey()
      }
      notifySuccess()
    }, [basePath, isOptimisticSave, isClonedRecord, resetFormKey, notifySuccess, onUpdateSuccess, redirect, isNewRecord])

    const save = useCallback(async (rawData) => {
      if (isOptimisticSave) resetFormKey()

      const data = transform ? transform(rawData) : rawData

      try {
        const result = await mutate({
          resource,
          type: isNewRecord ? 'create' : 'update',
          payload: isNewRecord ? {
            data
          } : {
            data,
            id: data.id
          },
        }, {
          returnPromise: true,
          action: CUSTOM_MUTATION,
        })
        onSuccess(result.data.id)
      } catch (error) {
        playErrorSound()
        if (isOptimisticSave) {
          alertOptimisticFailure(data)
        } else {
          notifyFailure(error)
          const { body } = error
          const { attributeErrors = {} } = body
          const resourceRelationships = relationships[resource]

          // Return value from async save sets form submitErrors
          // to be shown beneath appropriate field
          return Object.entries(attributeErrors).reduce((accum, [attr, errors]) => {
            const joinedErrors = errors.join('. ').trim()
            return {
              ...accum,
              [attr]: resourceRelationships?.hasOwnProperty(attr) ? { id: joinedErrors } : joinedErrors,
            }
          }, {})
        }
      }
    }, [
      mutate,
      resource,
      onSuccess,
      notifyFailure,
      resetFormKey,
      isNewRecord,
      isOptimisticSave,
      playErrorSound,
      alertOptimisticFailure,
      transform,
    ])

    const onDeleteSuccess = useCallback(() => {
      notifyDeleteSuccess()
      redirect('list', basePath)
    }, [basePath, redirect, notifyDeleteSuccess])

    const onDeleteFailure = useCallback((error) => {
      notifyFailure(error)
    }, [notifyFailure])

    const onSubmit = useCallback(formProps => {
      const { valid, handleSubmitWithRedirect, hasSubmitErrors } = formProps
      handleSubmitWithRedirect()
      if (!valid && !hasSubmitErrors) {
        playErrorSound()
      }
    }, [playErrorSound])

    useEffect(() => () => {
      // Reset form on cleanup effect if recordId change i.e. to handle route change
      resetFormKey()
    }, [recordId, resetFormKey])

    const { loading } = useEditContext(props)

    if (loading) return <Loading loadingSecondary={''} loadingPrimary={''} />

    return (
      <Component
        {...restProps}
        save={save}
        formKey={formKey}
        onDeleteSuccess={onDeleteSuccess}
        onDeleteFailure={onDeleteFailure}
        onSubmit={onSubmit}
        isNewRecord={isNewRecord}
        isClonedRecord={isClonedRecord}
      />
    )
  }
}

const formSubscription = {
  hasSubmitErrors: true,
  pristine: true,
  valid: true,
  submitting: true,
}

const Form = props => {
  const {
    infoText,
    isUpdateDisabled = false,
    isDeleteDisabled = false,
    save,
    formKey,
    children,
    onDeleteFailure,
    onDeleteSuccess,
    onSubmit,
    saveButtonLabel,
    isNewRecord,
    isClonedRecord,
    clonedRecord,
    ...restProps
  } = props

  const { resource, record } = props
  const formClasses = useFormStyles(restProps)
  const { onKeyDown, saveButtonRef, formRef } = useEnterToTab()
  const classes = useStyles()
  const refresh = useRefresh()

  const deleteConfirmText = useDeleteConfirmText({ record, resource })

  return (
    <FormWithRedirect
      {...formOptions}
      {...restProps}
      key={formKey}
      save={save}
      subscription={formSubscription}
      render={formProps => {
        return (
          <form
            className={formClasses.form}
            ref={formRef}
            autoComplete='off'
            onKeyDown={onKeyDown}
          >
            { children }
            {
              !(isUpdateDisabled && isDeleteDisabled) &&
              <ToolbarGrid container justify='space-between' alignItems='center'>
                <Grid item>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item>
                      {
                        !isUpdateDisabled &&
                        <SaveButton
                          buttonRef={saveButtonRef}
                          saving={formProps.submitting}
                          disabled={!formProps.valid}
                          handleSubmitWithRedirect={onSubmit.bind(null, formProps)}
                          label={saveButtonLabel != null ? saveButtonLabel : (isNewRecord ? 'ra.action.create' : 'ra.action.update')}
                        />
                      }
                    </Grid>
                    {
                      infoText &&
                      <Grid item>
                        <InfoTooltip title={infoText}>
                          <IconButton tabIndex='-1'>
                            <InfoIcon />
                          </IconButton>
                        </InfoTooltip>
                      </Grid>
                    }
                  </Grid>
                </Grid>

                {
                  clonedRecord &&
                  <Grid item>
                    <ButtonWithIcon
                      component={Link}
                      className={classes.cloneButton}
                      tabIndex={-1}
                      to={({ pathname: `/${resource}/create`, state: { record: clonedRecord }} )}
                      icon={<CloneIcon fontSize='small' />}
                      label='Clone'
                    />
                  </Grid>
                }

                {
                  <Grid item>
                    {
                      !isDeleteDisabled  &&
                      <DeleteButton
                        tabIndex='-1'
                        confirmTitle={deleteConfirmText}
                        mutationMode='pessimistic'
                        record={formProps.record}
                        onSuccess={onDeleteSuccess}
                        onFailure={onDeleteFailure}
                      />
                    }

                    {
                      isNewRecord && !isClonedRecord &&
                      <ButtonWithIcon
                        onClick={refresh}
                        tabIndex="-1"
                        label='Clear'
                        color='primary'
                        icon={ <ClearIcon fontSize='small' /> }
                      />
                    }
                  </Grid>
                }
              </ToolbarGrid>
            }
          </form>
        )
      }}
    />
  )
}

export default formHOC(Form)
