import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react'
import {
  ReferenceInput,
  TextInput,
  useDataProvider,
} from 'react-admin'
import { Box } from '@material-ui/core'
import get from 'lodash/get'
import { Autocomplete, DateInput, NumberInput, BooleanInput } from '../../custom'
import {
  referenceInputOptions,
  formInputOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import {
  useLabel,
  useTranslateResource,
} from '../../../hooks'
import { relationships } from '../../../data-model'
import { Form, TruckDriverFields, getValidator } from '../../form'
import {
  useTonnageRateFields,
  useTonnageRateReferenceFields,
  useReferenceLookup,
  useAutofillFields,
} from '../../form/functions'

const validate = getValidator()

const tonnageRateReferenceInputOptions = {
  sort: {
    field: 'code',
    order: 'ASC',
  },
}

const skipTabInputProps = {
  tabIndex: '-1',
}

const FormBody = props => {
  const { onRateChange, ...restProps } = props
  const { resource, record } = restProps
  const extraction = record.tonnage_ticket_extraction
  const isNewRecord = !record.id || Boolean(extraction)
  const resourceRelationships = relationships[resource]
  const formClasses = useFormStyles(restProps)
  const dataProvider = useDataProvider()
  const [impliedRate, setImpliedRate] = useState()
  const rateFields = useTonnageRateFields()
  const rateLookupReferenceFields = useTonnageRateReferenceFields()
  const label = useLabel(restProps)
  const translate = useTranslateResource(resource, 'form')
  const { selectedRecord: selectedRate, onSelectedIdChange: onSelectedRateIdChange } = useReferenceLookup(resourceRelationships['tonnage_rate'], record.tonnage_rate)

  const autofillRateReferenceFields = useMemo(() => {
    return rateLookupReferenceFields.map(f => `${f.source}.id`)
  }, [rateLookupReferenceFields])

  const autofillRateFields = useMemo(() => {
    return rateFields.map(f => f.source)
  }, [rateFields])

  const rate = useMemo(() => {
    return selectedRate || impliedRate
  }, [impliedRate, selectedRate])

  const shouldAutofillRateReferenceField = useCallback(() => {
    return isNewRecord
  }, [isNewRecord])

  const shouldAutofillRateField = useCallback(() => {
    return isNewRecord
  }, [isNewRecord])

  const shouldAutofillDriverField = useCallback(() => {
    return isNewRecord
  }, [isNewRecord])

  const [
    getRateReferenceFieldIsAutofilled,
    onAutofillRateReferenceFieldChange,
   ] = useAutofillFields(autofillRateReferenceFields, selectedRate, shouldAutofillRateReferenceField)

   const [
    getRateFieldIsAutofilled,
    onAutofillRateFieldChange,
   ]= useAutofillFields(autofillRateFields, rate, shouldAutofillRateField)

  const [rateReferenceFieldValues, setRateReferenceValues] = useState(() => {
    const { record } = props
    return rateLookupReferenceFields.reduce((accum, { source }) => {
      return {
        ...accum,
        [source]: get(record, [source, 'id']),
      }
    }, {})
  })

  const onRateLookupFieldChange = useCallback((field, value) => {
    setRateReferenceValues(prev => ({
      ...prev,
      [field]: value,
    }))
    onAutofillRateReferenceFieldChange(`${field}.id`, value)
  }, [onAutofillRateReferenceFieldChange])

  const getRateFieldHelperText = useCallback(field => {
    const value = get(rate, field)
    const autofilled = getRateFieldIsAutofilled(field)

    if (isNewRecord) {
      if (rate) {
        if (value == null) {
          return translate('general.autofilledBlankValueFromRate')
        } else {
          if (autofilled) {
            return translate('general.autofilledValueFromRate', { value })
          } else {
            return translate('general.currentRateValue', { value })
          }
        }
      } else {
        return translate('general.noRate')
      }
    } else {
      if (value == null) {
        return translate('general.autofilledBlankValueFromRate')
      } else {
        return translate('general.currentRateValue', { value })
      }
    }
  }, [rate, isNewRecord, translate, getRateFieldIsAutofilled])

  const selectedRateText = useMemo(() => {
    let text = translate('general.noRate')
    if (rate) {
      if (rate.code) {
        text = translate('general.selectedRateWithCode', {
          code: rate.code,
        })
      } else {
        text = translate('general.selectedRateWithoutCode', {
          description: rate.description,
        })
      }
    }
    return rate && !rate.is_active ? [text, `(${translate('general.inactiveRate')})`].join(' ') : text
  }, [rate, translate])

  const getExtractedValueHelperText = useCallback(field => {
    if (extraction) {
      const value = extraction[field]
      if (value) {
        return translate('edit.extractedText', { value })
      }
    }
  }, [extraction, translate])

  const getRateReferenceFieldHelperText = useCallback(field => {
    const texts = []
    texts.push(getExtractedValueHelperText(field.replace(/_id$/, '')))
    if (rate) {
      const value = get(rate, [field, 'name'])
      if (value) {
        const autofilled = getRateReferenceFieldIsAutofilled(`${field}.id`)
        texts.push(translate(`general.${autofilled ? 'autofilledValueFromRate' : 'currentRateValue'}`, { value }))
      }
    }
    const result = texts.filter(Boolean).join('\n')
    return result.length ? result : null
  }, [rate, getRateReferenceFieldIsAutofilled, translate, getExtractedValueHelperText])

  const validateTonnage = useMemo(() => {
    const max = 35
    return getValidator(true, [
      value => value > max ? translate('validate.tonnageTooHigh', { max }) : null,
    ])
  }, [translate])

  useEffect(() => {
    if (selectedRate) return
    if (Object.values(rateReferenceFieldValues).every(v => !v)) {
      setImpliedRate()
      return
    }
    (async () => {
      const filter = {
        is_active_eq: true,
        ...Object.entries(rateReferenceFieldValues).reduce((accum, [field, value]) => {
          return {
            ...accum,
            [`${field}_id_eq`]: value,
          }
        }, {}),
      }
      const responseData = await dataProvider.getList('tonnage_rates', { filter })
      setImpliedRate(responseData.data[0])
    })()
  }, [rateReferenceFieldValues, selectedRate, dataProvider])

  useEffect(() => {
    onRateChange(rate)
  }, [rate, onRateChange])

  return (
    <Box className={formClasses.grid}>
      <Box>
        <ReferenceInput
          {...formInputOptions}
          {...referenceInputOptions(resourceRelationships['tonnage_rate'])}
          {...tonnageRateReferenceInputOptions}
          source='tonnage_rate.id'
          reference={resourceRelationships['tonnage_rate']}
          label={translate('labels.tonnageRate')}
          onChange={onSelectedRateIdChange}
        >
          <Autocomplete
            optionText='code'
            optionSubtext='description'
            helperText={selectedRateText}
            matchFrom='start'
            autoFocus
          />
        </ReferenceInput>

        {
          rateLookupReferenceFields.map(({ source, required, optionText, optionSubtext, label: _label }) => {
            const sourceWithId = `${source}.id`
            return (
              <ReferenceInput
                {...formInputOptions}
                {...referenceInputOptions(resourceRelationships[source])}
                key={source}
                source={sourceWithId}
                reference={resourceRelationships[source]}
                label={label(_label)}
                onChange={onRateLookupFieldChange.bind(null, source)}
                required={required}
                inputProps={{
                  ...formInputOptions.inputProps,
                  ...(getRateReferenceFieldIsAutofilled(sourceWithId) ? skipTabInputProps : undefined),
                }}
              >
                <Autocomplete
                  validate={getValidator(required)}
                  optionText={optionText}
                  optionSubtext={optionSubtext}
                  helperText={getRateReferenceFieldHelperText(source)}
                />
              </ReferenceInput>
            )
          })
        }
      </Box>

      <Box>
        <TextInput
          {...formInputOptions}
          source='ticket_number'
          label={label('ticket_number')}
          validate={validate}
          required
        />

        <DateInput
          {...formInputOptions}
          validate={validate}
          source='worked_at_date'
          label={label('worked_at_date')}
          required
        />

        <NumberInput
          {...formInputOptions}
          source='net_weight_in_tons'
          label={label('net_weight_in_tons')}
          step={0.01}
          min={0}
          validate={validateTonnage}
          required
        />

        {
          rateFields.map(({ source, required, type, defaultValue, label: _label }) => {
            switch (type) {
              case 'number':
                return (
                <NumberInput
                    {...formInputOptions}
                    validate={getValidator(required)}
                    key={source}
                    source={source}
                    label={label(_label)}
                    step={0.01}
                    min={0}
                    required={required}
                    onChange={onAutofillRateFieldChange.bind(null, source)}
                    helperText={getRateFieldHelperText(source)}
                    inputProps={{
                      ...formInputOptions.inputProps,
                      ...((get(rate, source) != null) && getRateFieldIsAutofilled(source) ? skipTabInputProps : undefined),
                    }}
                  />
                )
              case 'boolean':
                return (
                  <BooleanInput
                    source={source}
                    key={source}
                    label={label(_label)}
                    defaultValue={defaultValue}
                    tabIndex={-1}
                    onChange={onAutofillRateFieldChange.bind(null, source)}
                    helperText={getRateFieldHelperText(source)}
                  />
                )
              default:
                return null
            }
          })
        }

      </Box>

      <Box>
        <TruckDriverFields
          {...restProps}
          truckHelperText={getExtractedValueHelperText('truck_number')}
          shouldAutofill={shouldAutofillDriverField}
        />
      </Box>
    </Box>
  )
}

// wrap component so we can use useForm hook
const TonnageTicketsForm = props => {
  const { resource, record } = props
  const [rate, setRate] = useState()
  const translate = useTranslateResource(resource, 'form')
  const isDisabled = Boolean(record.snapshot_mode)

  // Submit current tonnage_rate
  const transform = useCallback((data) => {
    return {
      ...data,
      tonnage_rate: {
        id: rate?.id,
      },
    }
  }, [rate])

  const getOptimisticFailureMessageContext = useCallback((data) => {
    return `#${data.ticket_number}`
  }, [])

  return (
    <Form
      {...props}
      infoText={translate('info.formHelper')}
      transform={transform}
      useOptimisticSave
      getOptimisticFailureMessageContext={getOptimisticFailureMessageContext}
      isUpdateDisabled={isDisabled}
      isDeleteDisabled={isDisabled}
    >
      <FormBody
        {...props}
        onRateChange={setRate}
      />
    </Form>
  )
}

export default TonnageTicketsForm
