import {
  Children,
  cloneElement
} from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import { DatagridHeaderCell, DatagridCell } from 'react-admin'
import classnames from 'classnames'

const useStyles = makeStyles(({ spacing }) => ({
  headerCell: {
    fontWeight: 'bold',
    borderBottomWidth: 0,
    padding: `${spacing(1)}px ${spacing(1.1)}px ${spacing(2.5)}px`,
    '&:has(*:empty)': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  rowCell: {
    borderWidth: 0,
    '&:has(*:empty)': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    padding: `${spacing(0.6)}px ${spacing(1.1)}px`,
  },
  rowCellTotal: {
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopStyle: 'double',
    borderTopColor: 'inherit',
    padding: `${spacing(1)}px ${spacing(1.1)}px ${spacing(5)}px`,
    '&:has(*:empty)': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  rowCellTotalContent: {
    fontWeight: 'bold',
  }
}))

const datagridCellDefaults = {
  currentSort: {},
  isSorting: false,
  updateSort: () => null,
}

const MyDatagrid = ({
  children,
  data,
}) => {
  const classes = useStyles()

  const totalsElements = Children.map(children, c => {
    const nextProps = Object.assign({},
    c.props, {
      className: classnames(c.props.className, classes.rowCellTotalContent),
    }, c.props.forceZero ? { forceZero: false } : null)
    return cloneElement(c, nextProps)
  })

  const headersElements = Children.map(children, c => cloneElement(c, {
    ...c.props,
    sortable: false,
    className: classnames(c.props.className, classes.headerCell)
  }))

  return (
    <Table>
      <TableHead>
        <TableRow>
          {
            headersElements.map((field, index) => (
              <DatagridHeaderCell
                {...datagridCellDefaults}
                className={classes.headerCell}
                field={field}
                key={index}
              />
            ))
          }
        </TableRow>
      </TableHead>
        {
          data.map((group, groupIndex) => {
            return (
              <TableBody key={groupIndex}>
                {
                  group.data.map((row, rowIndex) => {
                    return (
                      <TableRow key={`${groupIndex}-${rowIndex}`}>
                        {
                          Children.map(children, (field, fieldIndex) => {
                            return (
                              <DatagridCell
                                key={fieldIndex}
                                className={classes.rowCell}
                                field={field}
                                record={row}
                              />
                            )
                          })
                        }
                      </TableRow>
                    )
                  })
                }
                {
                  group.subtotal != null &&
                    <TableRow key={groupIndex}>
                      {
                        totalsElements.map((field, fieldIndex) => {
                          return (
                            <DatagridCell
                              className={classes.rowCellTotal}
                              key={fieldIndex}
                              field={field}
                              record={group.subtotal}
                            />
                          )
                        })
                      }
                    </TableRow>
                }
              </TableBody>
            )
          })
        }
    </Table>
  )
}

export default MyDatagrid
