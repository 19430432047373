import {
  ReferenceInput,
  TextInput,
  FormDataConsumer,
} from 'react-admin'
import { Box, Grid, makeStyles } from '@material-ui/core'
import { Autocomplete, DateInput, NumberInput, BooleanInput } from '../../custom'
import { useForm } from 'react-final-form'
import {
  referenceInputOptions,
  formInputOptions,
} from '../../util/component-options'
import { useFormStyles } from '../../../styles'
import {
  useLabel,
} from '../../../hooks'
import {
  useTonnageRateFields,
  useTonnageRateReferenceFields,
} from '../../form/functions'
import { relationships } from '../../../data-model'
import { Form, getValidator } from '../../form'
import AdjustNumeric from './adjust-numeric'

const useStyles = makeStyles(theme => ({
  rateFieldInputCol: {
    flexGrow: 1,
  },
  rateFieldAdjustControlCol: {
    marginTop: theme.spacing(2),
  },
}))

const FormBody = props => {
  const { resource } = props
  const resourceRelationships = relationships[resource]
  const formClasses = useFormStyles(props)
  const rateFields = useTonnageRateFields(false)
  const numberRateFields = rateFields.filter(({ type }) => type === 'number')
  const booleanRateFields = rateFields.filter(({ type }) => type === 'boolean')
  const label = useLabel(props)
  const classes = useStyles()
  const referenceFields = useTonnageRateReferenceFields()
  const form = useForm()
  const { change: changeFormField, resetFieldState } = form

  const onAcceptRateAdjustment = (attribute, value) => {
    changeFormField(attribute, value)
    resetFieldState(attribute)
  }

  return (
    <Box className={formClasses.grid}>
      <Box>
        <TextInput
          {...formInputOptions}
          source='code'
          label={label('code')}
          validate={getValidator(false)}
        />

        {
          numberRateFields.map(({ source, required: _required, label: _label }) => (
            <FormDataConsumer key={source}>
              {({ formData }) => {
                const { [source]: value } = formData
                return (
                  <Grid container key={source} spacing={1}>
                    <Grid item className={classes.rateFieldInputCol}>
                      <NumberInput
                        {...formInputOptions}
                        source={source}
                        label={label(_label)}
                        step={0.01}
                        min={0}
                        required={_required}
                      />
                    </Grid>
                    <Grid item className={classes.rateFieldAdjustControlCol}>
                      <AdjustNumeric
                        value={value}
                        attribute={source}
                        resource={resource}
                        disabled={!value}
                        onAccept={onAcceptRateAdjustment.bind(null, source)}
                      />
                    </Grid>
                  </Grid>
                )
              }}
            </FormDataConsumer>
          ))
        }
        <TextInput
          {...formInputOptions}
          source='description'
          label={label('description')}
          validate={getValidator(false)}
        />
      </Box>

      <Box>
        {
          referenceFields.map(({ source, required, label: _label}) => (
            <ReferenceInput
              {...referenceInputOptions(resourceRelationships[source])}
              {...formInputOptions}
              key={source}
              source={`${source}.id`}
              reference={resourceRelationships[source]}
              label={label(_label)}
              required={required}
            >
              <Autocomplete validate={getValidator(required)} />
            </ReferenceInput>
          ))
        }
      </Box>

      <Box>
        <DateInput
          {...formInputOptions}
          source='start_date'
          label={label('start_date')}
          validate={getValidator(false)}
        />

        <DateInput
          {...formInputOptions}
          source='end_date'
          label={label('end_date')}
          validate={getValidator(false)}
        />

        {
          booleanRateFields.map(({ source, defaultValue, label: _label }) => (
            <BooleanInput
              key={source}
              source={source}
              label={label(_label)}
              defaultValue={defaultValue}
              tabIndex={-1}
            />
          ))
        }

        <BooleanInput
          source='is_active'
          label={label('is_active')}
          defaultValue={true}
          tabIndex={-1}
        />
      </Box>
    </Box>
  )
}

const TonnageRatesForm = (props) => {
  const { record } = props
  const clonedRecord = (() => {
    if (!record.id) return
    const { id: _id, ...clonedRecordProps } = record
    return {
      ...clonedRecordProps,
      is_active: false,
    }
  })()

  return (
    <Form
      {...props}
      clonedRecord={clonedRecord}
    >
      <FormBody {...props} />
    </Form>
  )
}

export default TonnageRatesForm
