import React from 'react'
import {
  TextInput,
  ReferenceInput,
  BooleanInput,
} from 'react-admin'
import { Box } from '@material-ui/core'
import { Autocomplete } from '../../custom'
import {
  referenceInputOptions,
  formInputOptions,
} from '../../util/component-options'
import { relationships } from '../../../data-model'
import { useFormStyles } from '../../../styles'
import { useLabel } from '../../../hooks'
import { Form, getValidator } from '../../form'

const TrucksForm = props => {
  const { resource } = props
  const formClasses = useFormStyles(props)
  const label = useLabel(props)
  const resourceRelationships = relationships[resource]

  return (
    <Form
      {...props}
    >
      <Box className={formClasses.grid}>
        <Box>
          <TextInput
            {...formInputOptions}
            source='number'
            label={label('number')}
            validate={getValidator()}
            required
          />

          <BooleanInput
            source='is_active'
            label={label('is_active')}
            defaultValue={true}
          />

          <ReferenceInput
            {...referenceInputOptions(resourceRelationships['default_driver'])}
            {...formInputOptions}
            source='default_driver.id'
            reference={resourceRelationships['default_driver']}
            label={label('default_driver')}
          >
            <Autocomplete
              validate={getValidator()}
              required
            />
          </ReferenceInput>
        </Box>
      </Box>
    </Form>
  )
}

export default TrucksForm
