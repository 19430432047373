const overrides = {
  RaLayout: {
    root: {
      minWidth: 'initial',
    },
    content: {
      minWidth: 0,
    },
  },
  RaList: {
    content: {
      overflowX: 'hidden',
    },
  },
  MuiFormControl: {
    marginDense: {
      marginBottom: 12,
    },
  },
  MuiFormControlLabel: {
    root: {
      width: 'fit-content',
    },
  },
  RaBooleanField: {
    root: {
      justifyContent: 'center',
    },
  },
  MuiSnackbarContent: {
    message: {
      whiteSpace: 'pre-line',
    },
  },
  MuiDialogContentText: {
    root: {
      whiteSpace: 'pre-line',
    },
  },
  RaFilterFormInput: {
    body: {
      width: '100%'
    },
    spacer: {
      width: 0,
    },
  },
  RaListToolbar: {
    toolbar: {
      position: 'sticky',
      top: 48,
      zIndex: 3,
      padding: '0 !important',
      minHeight: '0 !important',
    },
  },
  MuiToolbar: {
    dense: {
      minHeight: 0,
    },
  },
  MuiTablePagination: {
    toolbar: {
      minHeight: 0,
    },
  },
  MuiFormHelperText: {
    root: {
      whiteSpace: 'pre-line',
    },
  },
  RaEdit: {
    card: {
      overflow: 'initial',
    },
  },
}

export default overrides
