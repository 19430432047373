import {
  TextField,
  DateField,
  FunctionField,
} from 'react-admin'
import {
  Typography,
} from '@material-ui/core'
import DatagridWithSubtotals from '../../custom/datagrid-with-subtotals'
import {
  useLabel,
  useTranslateResource,
  usePayRollupTickets,
} from '../../../hooks'
import {
  currencyFieldOptions,
  numberFieldOptions,
  dateFieldOptions,
} from '../../util/component-options'
import { DriverPayroll } from '../../shared'
import { NumberField } from '../../custom'

const TicketsGrid = ({
  record: {
    tonnage_tickets: tonnageTickets,
    hourly_tickets: hourlyTickets,
  }
}) => {
  const resource = 'driver_pay_rollup_tickets'
  const translate = useTranslateResource(resource, 'lines')
  const label = useLabel({ resource })

  const groupedTickets = usePayRollupTickets({ tonnageTickets, hourlyTickets})

  return (
    <DatagridWithSubtotals data={groupedTickets}>
      <DateField source='date' label={label('date')} options={dateFieldOptions} />
      <TextField source='ticket_number' label={label('ticket_number')} />
      <NumberField source='net_weight_in_tons' label={label('net_weight_in_tons')} options={numberFieldOptions} />
      <FunctionField render={record => record.is_flat_rate ? translate('flat_rate') : null} label={''} sortable={false} />
      <NumberField source='base_rate_in_dollars' label={label('base_rate_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='driver_percent_of_base_rate' label={label('driver_percent_of_base_rate')} />
      <NumberField source='regular_time_in_hours' label={label('regular_time_in_hours')} options={numberFieldOptions} forceZero />
      <NumberField source='regular_pay_rate_in_dollars' label={label('regular_pay_rate_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='overtime_in_hours' label={label('overtime_in_hours')} options={numberFieldOptions} forceZero />
      <NumberField source='overtime_pay_rate_in_dollars' label={label('overtime_pay_rate_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='doubletime_in_hours' label={label('doubletime_in_hours')} options={numberFieldOptions} forceZero />
      <NumberField source='doubletime_pay_rate_in_dollars' label={label('doubletime_pay_rate_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='regular_travel_time_in_hours' label={label('regular_travel_time_in_hours')} options={numberFieldOptions} />
      <NumberField source='regular_travel_time_pay_rate_in_dollars' label={label('regular_travel_time_pay_rate_in_hours')} options={currencyFieldOptions} />
      <NumberField source='overtime_travel_time_in_hours' label={label('overtime_travel_time_in_hours')} options={numberFieldOptions} />
      <NumberField source='overtime_travel_time_pay_rate_in_dollars' label={label('overtime_travel_time_pay_rate_in_hours')} options={currencyFieldOptions} />
      <NumberField source='doubletime_travel_time_in_hours' label={label('doubletime_travel_time_in_hours')} options={numberFieldOptions} />
      <NumberField source='doubletime_travel_time_pay_rate_in_dollars' label={label('doubletime_travel_time_pay_rate_in_hours')} options={currencyFieldOptions} />
      <NumberField source='total_pay_in_dollars' label={label('total_pay_in_dollars')} options={currencyFieldOptions} forceZero />
    </DatagridWithSubtotals>
  )
}

const EmployeeDriverPayroll = ({ record }) => {
  const translate = useTranslateResource('employee_driver_pay_rollups', 'labels.payroll')

  const {
    driver,
    total_in_dollars,
    tenant,
    period: { end_date: date },
  } = record

  const Detail = () => (
    <Typography align='right'>
      {translate('driverType')}
    </Typography>
  )

  return (
    <DriverPayroll
      tenant={tenant}
      driver={driver}
      date={date}
      total={total_in_dollars}
      detail={<Detail />}
      lineItems={<TicketsGrid record={record} />}
    />
  )
}

export default EmployeeDriverPayroll
